"use strict";

import loadJQueryUi from './loadJQueryUi';
import initModulesInScope from "@elements/init-modules-in-scope";


$.fn.zIndex = function() {
    /* backfill old zIndex function for old jquery ui datepicker*/
    return this.css("zIndex");
};

export function initInScope ($scope) {
    let $datepickerContainers = $scope.find('.js-datepicker');

    loadJQueryUi().then(function () {
        $datepickerContainers.each(function () {

            let $this = $(this);
            let $datepicker = $(this).find('.js-datepicker__input');
            let $altField = $(this).find('.js-datepicker__alt-field');
            //$datepicker.val( toLocalDateString($altField.val()) );

            let $inputFromDayShort = $('.js-datepicker__from-input-day--short');
            let $inputFromMonth = $('.js-datepicker__from-input-month');
            let $inputFromYear = $('.js-datepicker__from-input-year');
            let $inputFromDayFull = $('.js-datepicker__from-input-day--full');
            let blockedDates;
            let bookedDates;
            let fullybookedDates;
            let datepickerMinDate = false;
            let datepickerMaxDate = false;
            let ajaxSubmit = false;
            if ($(this).hasClass('js-datepicker--ajax-submit')) {
                ajaxSubmit = true;
            }

            if ($(this).attr('data-fullybooked-dates') !== '[]' && !!$(this).attr('data-fullybooked-dates')) {
                fullybookedDates = JSON.parse($(this).attr('data-fullybooked-dates'));
            } else {
                fullybookedDates =  false;
            }

            if ($(this).attr('data-booked-dates') !== '[]' && !!$(this).attr('data-booked-dates')) {
                bookedDates = JSON.parse($(this).attr('data-booked-dates'));
            } else {
                bookedDates =  false;
            }

            if ($(this).attr('data-blocked-dates') !== '[]' && !!$(this).attr('data-blocked-dates')) {
                blockedDates = JSON.parse($(this).attr('data-blocked-dates'));
            } else {
                blockedDates =  false;
            }

            if ($(this).attr('data-datepicker-min-date')) {
                datepickerMinDate = $(this).attr('data-datepicker-min-date');
            }

            if ($(this).attr('data-datepicker-max-date')) {
                datepickerMaxDate = $(this).attr('data-datepicker-max-date');
            }

            let options = {
                numberOfMonths:1,
                minDate: datepickerMinDate ? new Date(datepickerMinDate) : 0,
                maxDate: datepickerMaxDate ? new Date(datepickerMaxDate) : null,
                altFormat: 'yy-mm-dd',
                altField: $altField,
                nextText: '<span class="icon icon-arrow" aria-label="Next" title="Next"></span>',
                prevText: '<span class="icon icon-arrow" aria-label="Previous" title="Previous"></span>',
                dateFormat: 'yy-mm-dd',
                firstDay: 1,

                beforeShowDay: function(date) {
                    let newDate = $.datepicker.formatDate( "yy-mm-dd", date);

                    if(blockedDates) {
                        return [ blockedDates.indexOf(newDate) == -1 ]
                        return [false, ''];
                    }

                    if(fullybookedDates) {
                        if (fullybookedDates.indexOf(newDate) == -1) {
                            //not in range
                        } else {
                            return [true, 'is-fully-booked'];
                        }
                    }

                    if(bookedDates) {
                        if (bookedDates.indexOf(newDate) == -1) {
                            //not in range
                        } else {
                            return [true, 'has-booking'];
                        }
                    }

                    return [true, ''];
                },

                onSelect: function(dateString, inst) {
                    let selectedDate = new Date(inst.selectedYear, inst.selectedMonth, inst.selectedDay);
                    let $self = $(this);
                    $(this).trigger('change');
                    $altField.val(dateString);
                    //$datepicker.datepicker('setDate', dateString);
                    $datepicker.val(toLocalDateString(Date.parse(dateString)));


                    console.log(selectedDate);
                    console.log(Date.parse(dateString));


                    if(ajaxSubmit) {

                        let $result = $(this).closest('form').find('.js-ajax__result');
                        let $loadingOverlay = $(this).closest('form').find('.js-ajax__loading');
                        let url = $(this).closest('form').attr('data-ajax-url');

                        $loadingOverlay.attr('hidden', false);

                        $.ajax(url, {
                            type: "POST",
                            data: $(this).closest('form').serialize(),
                            dataType: 'json',
                            headers: {ajax: true}
                        }).done(function (result) {
                            if (result.success !== false) {
                                $result.html(result.content);
                            } else {
                                $result.html(result.content);
                            }
                        }).fail(function () {
                            //console.log('fail');
                        }).always(function () {
                            initModulesInScope();
                            $loadingOverlay.attr('hidden', 'hidden');
                        });

                    }
                }
            };

            if ($altField.val()) {
                options.defaultDate = +$altField.val();
            }

            function toLocalDateString(timestamp) {
                return new Date(+timestamp).toLocaleString(_config.lang, {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                });
            }

            addKeyboardSupport($($datepicker));

            $datepicker.datepicker(options);
            function addKeyboardSupport($datepicker) {
                function triggerSelect () {
                    $('.ui-datepicker-current-day').click();
                }
                let newDate = $datepicker.datepicker('getDate');

                $datepicker.on('keydown', function (evt) {
                    if(newDate == null) {
                        $datepicker.datepicker('setDate', new Date());
                        newDate = $datepicker.datepicker('getDate');
                    }

                    switch (evt.keyCode) {
                        case 37: /*left*/
                            newDate.setDate(newDate.getDate() - 1);
                            evt.preventDefault();
                            evt.stopImmediatePropagation();
                            break;
                        case 38: /*up*/
                            newDate.setDate(newDate.getDate() - 7);
                            evt.preventDefault();
                            evt.stopImmediatePropagation();
                            break;
                        case 39: /*right*/
                            newDate.setDate(newDate.getDate() + 1);
                            evt.preventDefault();
                            evt.stopImmediatePropagation();
                            break;
                        case 40: /*down*/
                            newDate.setDate(newDate.getDate() + 7);
                            evt.preventDefault();
                            evt.stopImmediatePropagation();
                            break;
                        case 33: /*page up*/
                            newDate.setDate(newDate.getDate() - 30);
                            evt.preventDefault();
                            evt.stopImmediatePropagation();
                            break;
                        case 34: /*page down*/
                            newDate.setDate(newDate.getDate() + 30);
                            evt.preventDefault();
                            evt.stopImmediatePropagation();
                            break;
                        case 13: /*enter*/
                            // selectedDate = newDate;
                            // triggerSelect();
                            newDate.setDate(newDate.getDate());
                            $altField.val(newDate.getTime());
                            $datepicker.datepicker('setDate', newDate);
                            $(this).trigger('change');
                            $datepicker.val(toLocalDateString(newDate.getTime()));
                            $datepicker.datepicker('hide');

                            evt.preventDefault();
                            evt.stopImmediatePropagation();
                            break;
                    }

                    if (newDate.getTime() !== $datepicker.datepicker('getDate').getTime()) {
                        $datepicker.datepicker('setDate', newDate);
                        $datepicker.val( toLocalDateString(newDate.getTime()));
                    }
                });
            }
        });
    });

}
