"use strict";

export function initInScope ($scope) {
    console.log('123');

    if (matchMedia('(max-width: 767px)').matches) {
        $('.js-scroll-to-storno').on('shown.bs.collapse', function(e) {
            $('html,body').animate({
                scrollTop: $(this).offset().top -120
            }, 500);
        });

    }
}
