"use strict";
import initModulesInScope from "@elements/init-modules-in-scope";
import { showNotification } from '@elements/alert-notification';
import fetch from '@elements/fetch'; // IE10 Polyfill

export function initInScope ($scope) {
    $scope.find('.js-busitem select').on('change',function (e) {
        e.preventDefault();
        e.stopImmediatePropagation();

        let $busItem = $(this).closest('.js-busitem');
        let $busItemInput = $busItem.find('select');
        let $loadingOverlay = $busItem.find('.js-busitem__loading');
        let $result = $busItem.find('.js-busitem__result');
        let url = $busItem.attr('data-ajax-url');

        $loadingOverlay.attr('hidden', false);


        let pendingRequest = null;

        pendingRequest = fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: new URLSearchParams($busItemInput.serialize())
        });

            showNotification(pendingRequest);

            pendingRequest.then(response => response.json())
            .then(function (result) {

                //console.log(result.content);

                $result.html(result.content);
                $loadingOverlay.attr('hidden', 'hidden');

                $loadingOverlay.attr('hidden', 'hidden');
                initModulesInScope();
                pendingRequest = null;
            }).catch(function (jqXHR, textStatus, errorThrown) {
                if (textStatus !== 'abort') {
                    $loadingOverlay.attr('hidden', 'hidden');
                    console.error(textStatus, errorThrown, jqXHR);
                }
                $loadingOverlay.attr('hidden', 'hidden');


                pendingRequest = null;
            });

    });
}
