"use strict";

export function initInScope ($scope) {
    $scope.find('.js-paypal-checkbox input').on('change',function (e) {
        console.log('change');
        if($(this).prop('checked')) {
            $scope.find('.js-paypal-container').removeClass('is-disabled');
        } else {
            $scope.find('.js-paypal-container').addClass('is-disabled');
        }
    });
}
